<template>
    <div class="home">
        <LoaderCircular v-if="loading" class="mt-5" />
        <div v-else>
            <h1>Home</h1>
            <p>
                <router-link to="/example">Hochzeit Beispiel</router-link>
            </p>
            <p>
                <router-link to="/hochzeit-bsc-yb">Hochzeit BSC YB</router-link>
            </p>
            <p>
                <router-link to="/hochzeit-mirjam-philipp"
                    >Hochzeit Mirjam & Philipp</router-link
                >
            </p>
        </div>
    </div>
</template>

<script setup>
import { useSiteStore } from '@/stores/SiteStore';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import LoaderCircular from '../components/componentunits/LoaderCircular.vue';

const storeSite = useSiteStore();
storeSite.unloadSite();

const router = useRouter();

const loading = ref(true);

storeSite.getSiteByHost().then((siteId) => {
    if (siteId) {
        console.log('redirect to', `/${siteId}`);
        router.replace(`/${siteId}`);
    } else {
        loading.value = false;
    }
});
</script>
