<template>
    <v-app-bar
        :elevation="2"
        density="compact"
        class="bg-bgfocus"
        hide-on-scroll
    >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ storeSite.site?.data?.name }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" class="bg-bgnav">
        <v-list-item link :to="`/${$route.params.site}`">
            <v-list-item-title class="text-h6">
                {{ storeSite.site?.data?.name }}
            </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
            <v-list-item
                :key="page.id"
                v-for="page in storeSite.pagesNav"
                link
                :to="`/${$route.params.site}/${page.id}`"
            >
                <v-list-item-title>{{ page?.data?.name }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-footer :elevation="3" app absolute class="bg-bgfocus">
        <span class="text-caption">
            &copy; Copyright by
            {{ storeSite.site?.data?.owner ?? 'Yannik Beimler' }} -
            {{ new Date().getFullYear() }} -
            <span class="nowrap"
                >Code & Design by
                <a href="mailto:yannik@beimler.ch">Yannik Beimler</a></span
            >
            <span v-if="storeSite.site?.data?.additionalFooter">
                -
                <span
                    class="nowrap"
                    v-html="storeSite.site?.data?.additionalFooter"
                ></span
            ></span>
        </span>
    </v-footer>
</template>

<script setup>
import { watch, ref } from 'vue';
import { useSiteStore } from '@/stores/SiteStore';
import { useRoute } from 'vue-router';

const route = useRoute();
const storeSite = useSiteStore();

const drawer = ref();

watch(
    () => route.params.site,
    () => storeSite.loadSite()
);
</script>

<style>
.v-footer a {
    text-decoration: none;
    color: inherit;
}
</style>
