<template>
    <div class="site">
        <div v-if="showDebugInfo">
            <p>siteRoute: {{ storeSite.siteRoute ?? 'null' }}</p>
            <p>siteHasContent: {{ storeSite.siteHasContent }}</p>
            <p>pageRoute: {{ storeSite.pageRoute ?? 'null' }}</p>
            <p>
                pageCurrent: {{ storeSite.pageCurrent?.data?.name ?? 'null' }}
            </p>
            <p>
                showStart: {{ storeSite.pageCurrent?.data?.showStart ?? false }}
            </p>
            <p>
                onlyAnonymous:
                {{ storeSite.pageCurrent?.data?.onlyAnonymous ?? false }}
            </p>
        </div>
        
        <LoaderCircular v-if="storeSite.loading" class="mt-5" />
        <SiteStart
            v-else-if="
                storeSite.siteHasContent &&
                (storeSite.pageRoute == null ||
                    (storeSite.pageCurrent?.data?.showStart &&
                        !storeSite.pageCurrent?.data?.onlyAnonymous))
            "
        />
        <PageContainer
            v-else-if="
                storeSite.siteHasContent && storeSite.pageCurrent != null
            "
            :page="storeSite.pageCurrent"
        />
        <div v-else>404</div>
    </div>
</template>

<script setup>
import SiteStart from './SiteStart.vue';
import { useSiteStore } from '@/stores/SiteStore';
import PageContainer from '@/components/pages/PageContainer.vue';
import LoaderCircular from '../componentunits/LoaderCircular.vue';

const showDebugInfo = false;

const storeSite = useSiteStore();
</script>
