<template>
    <v-container fluid class="px-0 -px-md-4">
        <PageContainer
            :key="page.id"
            v-for="page in storeSite.pagesStart"
            :page="page"
            isStartpage
        />
    </v-container>
</template>

<script setup>
import { useSiteStore } from '@/stores/SiteStore';
import PageContainer from '@/components/pages/PageContainer.vue';

const storeSite = useSiteStore();
</script>
