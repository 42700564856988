import { defineStore } from 'pinia';
import { computed } from 'vue';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useBaseStore } from './BaseStore';

export const useMailStore = defineStore('mail', () => {
    /* Stores */
    const storeBase = useBaseStore();

    /* Mail */
    function saveMail(mail, fields) {
        const text = _getText(mail, fields);
        const to = _getRecipients(mail, fields);
        const subject = _getSubject(mail);
        const replyTo = _getReplyTo(mail);

        if (!mail || !text || !to || !subject) {
            console.log('no mail', to, subject, text);
            return;
        }
        console.log('save mail', to, subject, text, replyTo);

        const mailObject = {
            to: to,
            message: {
                subject: subject,
                html: text,
            },
            date: serverTimestamp(),
        };
        if (replyTo) mailObject.replyTo = replyTo;

        addDoc(_mailCollectionRef.value, mailObject)
            .then(() => {
                console.log('mail saved');
            })
            .catch((error) => {
                console.error('Error saveMail:', error);
            });
    }

    function _getText(mail, fields) {
        if (!mail?.text) return null;
        let text = mail.text;
        fields.forEach((field) => {
            const placeholder = `%${field.name}%`;
            text = text.replaceAll(placeholder, field.value);
        });
        text = text.replaceAll(
            '%fields%',
            fields.map(_getFieldLabelValue).join('<br/>')
        );
        return text;
    }

    function _getFieldLabelValue(field) {
        if (field.value != null) {
            let value = field.value;
            if (value === true) value = 'Ja';
            if (value === false) value = 'Nein';
            if (typeof value === 'string' || value instanceof String)
                value = value.replaceAll('\n', '<br/>');
            return `${field.label}: ${value}`;
        }
        return field.label;
    }

    function _getRecipients(mail, fields) {
        const sender = fields?.find(
            (field) => field.name === 'email' || field.name === 'mail'
        )?.value;
        const recipients = mail?.recipients ?? [];
        if (sender) recipients.push(sender);
        return recipients;
    }

    function _getSubject(mail) {
        return mail?.subject;
    }

    function _getReplyTo(mail) {
        return mail?.replyTo;
    }

    /* Firebase */
    const _db = computed(() => {
        return storeBase.db;
    });

    const _mailCollectionRef = computed(() => {
        return collection(_db.value, 'mail');
    });

    return {
        saveMail,
    };
});
