<template>
    <div class="objects pa-4">
        <h1>Objects</h1>
        <v-text-field v-model="pageName"></v-text-field>
        <p><v-btn @click="loadResults">Load Results</v-btn></p>
        <h2>Results</h2>
        <p>
            <v-btn
                :href="`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`"
                :download="`FormResults${pageName.replaceAll('/', '_')}.csv`"
            >
                Download
            </v-btn>
        </p>
        <h3>Keys</h3>
        <pre>{{ keys }}</pre>
        <h3>CSV</h3>
        <pre>{{ csv }}</pre>
        <h3>Pages</h3>
        <pre>{{ results }}</pre>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useBaseStore } from '@/stores/BaseStore';
import { collection, getDocs } from 'firebase/firestore';

const storeBase = useBaseStore();

const pagesRef = computed(() =>
    collection(storeBase.db, `${pageName.value}/formResults`)
);

const pageName = ref('/sites/hochzeit-bsc-yb/pages/anmeldung');
const results = ref([]);
const keys = ref([]);

async function loadResults() {
    const querySnapshot = await getDocs(pagesRef.value);
    const arrR = [];
    const arrK = ['id', 'date'];
    querySnapshot.forEach((doc) => {
        const form = {
            id: doc.id,
            date: doc.data().date.toDate().toISOString(),
        };
        doc.data().fields.forEach((field) => {
            form[field.name] = field.value;
            if (!arrK.includes(field.name)) arrK.push(field.name);
        });
        arrR.push(form);
    });
    results.value = arrR;
    keys.value = arrK;
}

const csv = computed(() => {
    if (!keys.value) return false;

    const string = [
        keys.value,
        ...results.value.map((r) =>
            keys.value.map((k) =>
                `${r[k]}`.replaceAll('\n', '   ').replaceAll(';', ',')
            )
        ),
    ]
        .map((e) => e.join(';'))
        .join('\n');

    console.log(string);

    return string;
});
</script>
