<template>
    <div class="objects pa-4">
        <h1>Admin Login</h1>
        <p>
            Login Status:
            <span v-if="storeAuth.loggedIn">
                Eingeloggt<br />
                {{ storeAuth.user?.displayName }}
                <br />
                {{ storeAuth.user?.email }}
                <br />
                ({{ storeAuth.user?.uid }})
            </span>
            <span v-else> Nicht eingeloggt </span>
        </p>
        <p>&nbsp;</p>
        <v-btn
            @click="storeAuth.logInAdmin"
            class="bg-bgsend"
            v-if="!storeAuth.loggedIn"
            >Login</v-btn
        >
        <v-btn v-else @click="storeAuth.logOut" class="bg-bgsend">
            Logout
        </v-btn>
    </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore';

const storeAuth = useAuthStore();
</script>
