const pageTypes = [
    {
        name: 'content',
        component: () => import('./PageTypeContent.vue'),
    },
    {
        name: 'form',
        component: () => import('./PageTypeForm.vue'),
    },
    {
        name: 'authentication',
        component: () => import('./PageTypeAuthentication.vue'),
    },
    {
        name: 'wishlist',
        component: () => import('./PageTypeWishlist.vue'),
    },
];

export { pageTypes };
