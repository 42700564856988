<template>
    <v-main
        class="main bg-bgcontainer"
        :class="{ wide: wide }"
        :style="{ backgroundImage: bgImg && `url('${bgImg}') !important` }"
    >
        <v-container fluid class="pa-0 -pa-1 -pa-md-4">
            <router-view :key="$route.path" />
        </v-container>
    </v-main>
</template>

<script setup>
import { useDisplay } from 'vuetify';
import { computed } from 'vue';
import { useSiteStore } from '@/stores/SiteStore';

const storeSite = useSiteStore();

const { height, width } = useDisplay();
const orientation = computed(() => width.value / height.value);
const bgObj = computed(() => storeSite.backgroundImage);
const wide = computed(
    () =>
        bgObj.value?.breakpoint &&
        bgObj.value?.wide &&
        orientation.value > bgObj.value.breakpoint
);
const bgImg = computed(() => {
    if (!bgObj.value?.default) return null;
    if (wide.value) return bgObj.value.wide;
    return bgObj.value.default;
});
</script>

<style scoped>
.main {
    background: no-repeat top center fixed !important;
    background-size: cover !important;
}
.main.wide {
    background-position-y: center !important;
}
</style>
