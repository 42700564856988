<template>
    <component :is="pageType" :page="page" v-if="isStartpage" />
    <v-container fluid class="px-0 -px-md-4" v-else>
        <component :is="pageType" :page="page" />
    </v-container>
</template>

<script setup>
import { defineProps } from 'vue';
import { computed, defineAsyncComponent } from 'vue';
import { pageTypes } from './pageTypes';

const props = defineProps({
    page: {
        type: Object,
        required: true,
    },
    isStartpage: {
        type: Boolean,
        required: false,
    },
});

const pageTypeName = computed(() => {
    return props.page?.data?.type;
});

const pageType = computed(() => {
    const t = pageTypes.find(
        (pageType) =>
            pageType.name.toLowerCase() === pageTypeName.value.toLowerCase()
    );
    if (!t) {
        console.warn('no page type found for: ' + pageTypeName.value);
        return null;
    }
    return defineAsyncComponent(() => t.component());
});
</script>
