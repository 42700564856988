import { createWebHistory, createRouter } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import SiteView from '@/views/SiteView.vue';
// import FirebaseView from '@/views/FirebaseExampleView.vue';
// import CounterView from '@/views/CounterView.vue';
// import CounterView2 from '@/views/CounterView2.vue';
import ObjectsView from '@/views/ObjectsView.vue';
// import ExamplesView from '@/views/ExamplesView.vue';
import ResultsView from '@/views/ResultsView.vue';
import LoginView from '@/views/LoginView.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
    },
    // {
    //     path: '/examples',
    //     name: 'Examples',
    //     component: ExamplesView,
    // },
    // {
    //     path: '/examples/firebase',
    //     name: 'Firebase',
    //     component: FirebaseView,
    // },
    // {
    //     path: '/examples/counter',
    //     name: 'Counter',
    //     component: CounterView,
    // },
    // {
    //     path: '/examples/counter2',
    //     name: 'Counter2',
    //     component: CounterView2,
    // },
    {
        path: '/admin/objects',
        name: 'Objects',
        component: ObjectsView,
    },
    {
        path: '/admin/results',
        name: 'Results',
        component: ResultsView,
    },
    {
        path: '/admin',
        name: 'Login',
        component: LoginView,
    },
    {
        path: '/:site',
        name: 'Site',
        component: SiteView,
    },
    {
        path: '/:site/:page',
        name: 'Page',
        component: SiteView,
    },
    {
        path: '/:site/:page/:detail',
        name: 'Detail',
        component: SiteView,
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});
export default router;
