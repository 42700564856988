import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyBZDVNGpclizC5xL16YJ-SC6tYb_l6wyrM',
    authDomain: 'yb-wedding-website.firebaseapp.com',
    projectId: 'yb-wedding-website',
    storageBucket: 'yb-wedding-website.appspot.com',
    messagingSenderId: '126171173620',
    appId: '1:126171173620:web:ed226cd6197ae023ea44a1',
    measurementId: 'G-YEJF9091ZD',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, analytics };
